// --- Vendors ---
import Swiper, { Navigation, Pagination, Thumbs, Mousewheel } from 'swiper';
Swiper.use([Navigation, Pagination, Thumbs, Mousewheel]);
// --- Components ---
const swiper = {
    readyFn: function () {
        let swiperArray = [];
        $('.cell-repeteur').each(function (index) {
            let containerID = $(this).attr('id');
            let swiperId = $(".swiper-container", this).attr('id');
            let prevBtn = document.getElementById(containerID).querySelector('.swiper-prev');
            let nextBtn = document.getElementById(containerID).querySelector('.swiper-next');
            let params = {
                spaceBetween: 0,
                loop: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                navigation: {
                    prevEl: prevBtn,
                    nextEl: nextBtn
                }
            }

            let slider = new Swiper('#' + swiperId, params);
        });
    },
}
export { swiper };