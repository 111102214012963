// --- Vendors ---
import Swiper, {
    Navigation,
    Pagination,
    Autoplay
} from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);
import { utils } from '../components/_utils';

// --- Components ---
const homePage = {

    readyFn: function () {
        let self = this;
        this.video();
        this.repeteur();

    },

    video: function () {
        $("#video .cell-video").on("click", function () {
            $(".fadeout", this).fadeOut();
            $("#mavideo")[0].play();
        });
    },

    repeteur: function () {
        // Vérifier si la largeur de l'appareil est inférieure à une certaine valeur considérée comme mobile
        if (utils.getDeviceKind() == 'isMobile') {
            $("#repeteur .cell-repeteur").on("click", function () {
                $(this).addClass("actif");
            });
        }
    }



}
export { homePage };